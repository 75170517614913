import { Transition } from '@headlessui/react';
import Link from 'components/links/Link';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Lupa, User } from 'kzv-icons';

import pages from '../../config/pages';
import AppContext from '../../contexts/AppContext';
import HamburgerButton from '../buttons/HamburgerButton';
import IconButton from '../buttons/IconButton';
import LinkUnderlineCurve from '../curves/LinkUnderlineCurve';
import MobileMenu from './MobileMenu';
import { setLoginDialogActive, setSearchDialogActive } from 'contexts/AppActions';
import Button from 'components/buttons/Button';
import useUser from 'hooks/useUser';
import UserAvatar from 'components/user/UserAvatar';

export const links = [
    {
        label: 'Ideje za izlet',
        href: pages.ideas.url
    },
    {
        label: 'Zemljevid',
        href: `${pages.ideas.url}?map=true`
    },
    // {
    //     label: 'Namigi',
    //     href: pages.namigi.url
    // },
    {
        label: 'Nagradni izbor',
        href: pages.photoContest.url
    },
    {
        label: 'Dodaj idejo',
        href: pages.suggest.url
    }
];

function MainMenu() {
    const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [isSticky, setIsSticky] = useState(true);
    const [minimal, setMinimal] = useState(false);
    const router = useRouter();
    const { dispatch } = useContext(AppContext);
    const { user } = useUser();

    const hasBorder = router.asPath !== '/';

    useEffect(() => {
        const shouldntBeSticky = ['/ideje', '/ideja/[slug]'].find((page) => router.pathname.includes(page));
        const shouldBeMinimal = ['/prijava', '/registracija', '/zakljuci-registracijo'].find((page) =>
            router.pathname.includes(page)
        );

        setMinimal(!!shouldBeMinimal);

        if (mobileMenuOpened) {
            setMobileMenuOpened(false);
        }

        if (shouldntBeSticky) {
            setIsSticky(false);
        } else {
            window.addEventListener('scroll', onScroll);
            setIsSticky(true);
        }

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [router.asPath]);

    const onScroll = () => {
        const { scrollY } = window;
        if (scrollY > 150) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    return (
        <>
            <div
                className={`${isSticky ? 'sticky' : 'relative'} w-full left-0 top-0 bg-white z-[100] transition
                ${hasBorder ? 'border-b border-gray-100' : ''} 
                ${scrolled || mobileMenuOpened ? 'shadow-lg' : ''}`}
            >
                <div className="container mx-auto px-2 sm:px-4 py-2 relative z-50">
                    <div className="flex justify-between items-center">
                        <div className="logo h-10 sm:h-16 flex-shrink-0">
                            <Link to="/">
                                <img src="/images/logo.svg" alt="Kam za vikend logo" className="h-full" />
                            </Link>
                        </div>
                        {!minimal && (
                            <>
                                <div className="main-menu">
                                    <div className="tasty-burger lg:hidden flex space-x-1 items-center">
                                        <div className="relative">
                                            {!user && (
                                                <div className="login text-primary">
                                                    <Button
                                                        startIcon={<User strokeWidth={1.5} />}
                                                        variant={'contained'}
                                                        styles={'text-primary text-sm font-medium px-3'}
                                                        onClick={() => setLoginDialogActive(dispatch, true)}
                                                    >
                                                        <span>Prijavi se</span>
                                                    </Button>
                                                </div>
                                            )}
                                            {user?.completed_registration_at && (
                                                <NextLink href="/profil" className="flex gap-2 ml-4 mr-2">
                                                    <UserAvatar />
                                                    <div className="flex flex-col leading-none">
                                                        <span>Hej</span>
                                                        <span className="text-brand font-medium">
                                                            {user.first_name}
                                                        </span>
                                                    </div>
                                                </NextLink>
                                            )}
                                        </div>
                                        <div>
                                            <HamburgerButton
                                                handleClick={() => setMobileMenuOpened(!mobileMenuOpened)}
                                                isActive={mobileMenuOpened}
                                            />
                                        </div>
                                    </div>
                                    <nav className="hidden lg:flex items-center">
                                        {links.map((link, i) => (
                                            <div key={i}>
                                                {router.asPath === link.href ? (
                                                    <LinkUnderlineCurve color="#FFD272">
                                                        <span className="mx-2 px-4 py-2 font-medium tracking-wide cursor-default">
                                                            {link.label}
                                                        </span>
                                                    </LinkUnderlineCurve>
                                                ) : (
                                                    <Link
                                                        to={link.href}
                                                        styles="mx-2 px-4 py-2 font-medium tracking-wide hover:text-brand transition duration-60 translate-x"
                                                    >
                                                        {link.label}
                                                    </Link>
                                                )}
                                            </div>
                                        ))}
                                        {!user && (
                                            <div className="login text-primary mx-4">
                                                <Button
                                                    startIcon={<User strokeWidth={1.5} />}
                                                    variant={'contained'}
                                                    styles={'text-primary font-medium'}
                                                    onClick={() => setLoginDialogActive(dispatch, true)}
                                                >
                                                    <span>Hej, prijavi se</span>
                                                </Button>
                                            </div>
                                        )}
                                        {user?.completed_registration_at && (
                                            <NextLink href="/profil" className="flex gap-2 ml-4 mr-2">
                                                <UserAvatar />
                                                <div className="flex flex-col leading-none">
                                                    <span>Hej,</span>
                                                    <span className="text-brand font-medium">{user.first_name}</span>
                                                </div>
                                            </NextLink>
                                        )}

                                        <div className="search relative ml-2">
                                            <IconButton onClick={() => setSearchDialogActive(dispatch, true)}>
                                                <Lupa width={24} height={24} />
                                            </IconButton>
                                        </div>
                                    </nav>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <Transition
                show={mobileMenuOpened}
                as={Fragment}
                enter="transition transform ease-out duration-200"
                enterFrom="transform translate-x-full"
                enterTo="transform translate-x-0"
                leave="transition transform ease-in duration-150"
                leaveFrom="transform translate-x-0"
                leaveTo="transform translate-x-full"
            >
                <MobileMenu links={links} />
            </Transition>
        </>
    );
}

export default MainMenu;
